import * as React from "react"
import {useLocation} from "@reach/router"

import AppHeader from "../components/app-header"
import AppPageProgressBar from "../containers/app-page-progress-bar"

import "./dashboard-layout.scss"

const DashboardLayout = ({children}) => {
  const {pathname} = useLocation()

  return (
    <div className="dashboard-layout-container">
      <AppHeader />
      <div
        className={`dashboard-layout-page-content-container p-relative ${pathname}`}>
        <AppPageProgressBar />
        {children}
      </div>
    </div>
  )
}

export default DashboardLayout
