import * as React from "react"
import {QueryClient, QueryClientProvider, onlineManager} from "react-query"
import {ReactQueryDevtools} from "react-query/devtools"

import {defaultRetry} from "./utils"

// this would cause the query to failed if there's no network
// (or, default would be paused)
onlineManager.setEventListener(setOnline => {
  setOnline(true)
  return () => undefined
})

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: defaultRetry(undefined, 2),
    },
    mutations: {
      retry: defaultRetry(undefined, 1),
    },
  },
})

export const QueryProvider = ({children}) => {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
