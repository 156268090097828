import * as React from "react"
import classNames from "classnames"
import {navigate} from "gatsby"

import {IconButton} from "./base/icon-button"
import {Icon} from "./base/icon"
import {noop} from "../utils/noop"

/**
 * @typedef {object} NavigationMenuItemData
 * @property {string} name
 * @property {string} id
 * @property {string} icon
 * @property {string=} href
 * @property {() => void=} onClick
 */
/**
 * @typedef {object} NavigationMenuItemProps
 * @property {string} className
 */

/**
 * @type {React.FC<NavigationMenuItemProps & NavigationMenuItemData>}
 * @param {NavigationMenuItemProps & NavigationMenuItemData} param0
 */
const NavigationMenuItem = ({name, icon, className, href, onClick}) => {
  const disabled = !href && !onClick

  const onClickHandler = React.useCallback(() => {
    if (onClick) {
      onClick()
    } else if (href) {
      navigate(href)
    }
  }, [onClick, href])

  return (
    <div
      onClick={onClickHandler}
      onKeyDown={noop}
      role="button"
      tabIndex="0"
      className={classNames("component-base-button row", className, {
        disabled,

        anchor: !disabled,
        "component-base-icon-button-parent": !disabled,
      })}>
      {disabled ? (
        <Icon icon={icon + "-disabled"} size={30} />
      ) : (
        <IconButton icon={icon} size={30} />
      )}
      {name}
    </div>
  )
}

export default NavigationMenuItem
