import * as React from "react"
import {isCancelledError} from "react-query"

import {Spacing} from "./base/spacing"

import IMG_ERROR from "../images/img-error.svg"
import IMG_NO_DATA from "../images/img-no-data.svg"
import {isNull} from "../utils/is-null"
import {defaultErrorTitle} from "../utils/general-error"

const isNoData = data => {
  return isNull(data) || data?.length === 0
}

/**
 * @type {React.FC<DefaultPlaceholderProps>}
 * @param {DefaultPlaceholderProps} param0
 */
const DefaultPlaceholder = ({
  error,
  data,
  nodata = false,
  forceError = false,
  errorMessage = "We can’t seem to find the page you’re looking for.",
  errorTitle = "Oops!",
  noDataMessage = "No Data!",
  noDataPlaceholder,
  children,
}) => {
  if (
    error &&
    !isCancelledError(error) &&
    (forceError || nodata || isNoData(data))
  ) {
    return (
      <div className="placeholder-container column-center">
        <img src={IMG_ERROR} alt={IMG_ERROR} width={500} height={373} />
        <Spacing spacing="l" />
        <div className="text-heading-2">
          {defaultErrorTitle(error) || errorTitle}
        </div>
        <Spacing height={14} />
        <div>{errorMessage}</div>
      </div>
    )
  }

  if (nodata || isNoData(data)) {
    return (
      noDataPlaceholder || (
        <div className="placeholder-container column-center">
          <img src={IMG_NO_DATA} alt={IMG_NO_DATA} width={153} height={150} />
          <Spacing spacing="l" />
          <div className="no-data">{noDataMessage}</div>
        </div>
      )
    )
  }

  return typeof children === "function" ? children({data, error}) : children
}

export default DefaultPlaceholder

/**
 * @typedef {object} DefaultPlaceholderProps
 * @property {Error} error
 * @property {any|Array} data
 * @property {boolean=} nodata
 * @property {boolean=} forceError - always show error placeholder if there's error (even data has value)
 * @property {string=} errorMessage
 * @property {string=} errorTitle
 * @property {string=} noDataMessage
 * @property {any=} noDataPlaceholder
 */
