import * as React from "react"

import DefaultPlaceholder from "./default-placeholder"
import {
  NO_INTERNET_CODE,
  NO_INTERNET_MSG,
  REQUEST_TIMEOUT_CODE,
  REQUEST_TIMEOUT_MSG,
} from "../utils/general-error"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
    console.log("boundary error", error, errorInfo)
    //   // You can also log the error to an error reporting service
    //   // logErrorToMyService(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      const error = new Error(REQUEST_TIMEOUT_MSG)
      error.code = REQUEST_TIMEOUT_CODE
      if (navigator?.onLine === false) {
        error.code = NO_INTERNET_CODE
        error.message = NO_INTERNET_MSG
      }
      // render fallback UI
      return (
        <DefaultPlaceholder
          error={error}
          forceError
          nodata={false}
          data={[]}></DefaultPlaceholder>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
