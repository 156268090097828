import {useMutation as useMutationOrig} from "react-query"

import {useDefaultOnErrorHandler, useDefaultOnSuccessHandler} from "./utils"

/**
 * @param {import('react-query').MutationFunction} mutationFn
 * @param {import('./types').CustomUseMutationOptions=} options
 */
export function useMutation(mutationFn, options) {
  const {
    onError,
    onSuccess,
    errorMessage,
    successMessage,
    ...restOptions
  } = options

  const onErrorHandler = useDefaultOnErrorHandler(onError, {errorMessage})
  const onSuccessHandler = useDefaultOnSuccessHandler(onSuccess, successMessage)

  return useMutationOrig(mutationFn, {
    ...restOptions,
    onError: onErrorHandler,
    onSuccess: onSuccessHandler,
  })
}
