import * as React from "react"
import {Router} from "@reach/router"
import Loadable from "@loadable/component"

// initialize services
import {configureAmplify} from "../lib/aws/configure"

import DashboardLayout from "../app-layouts/dashboard-layout"

const LoadableEmployeePage = Loadable(() =>
  import(/* webpackPrefetch: true */ "./app-employees"),
)
const LoadableContactDetailPage = Loadable(() =>
  import(/* webpackPrefetch: true */ "./app-contact-detail"),
)
const LoadableSettingsPage = Loadable(() =>
  import(/* webpackPrefetch: true */ "./app-settings"),
)

configureAmplify()

function AppRoute() {
  return (
    <DashboardLayout>
      <Router>
        <LoadableEmployeePage default path="/employee" />
        <LoadableContactDetailPage path="/employee/ct" />
        <LoadableSettingsPage path="/settings" />
      </Router>
    </DashboardLayout>
  )
}

export default AppRoute
