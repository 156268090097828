import * as React from "react"
import {navigate} from "gatsby"

import {Spacing} from "./base/spacing"
import {IconButton} from "./base/icon-button"
import {Icon} from "./base/icon"
import {useToggle} from "../hooks/toggle"
import {getWorkspace, logout} from "../services/auth"

import IC_WORKSPACE from "../images/ic-workspace.svg"

import NavigationMenu from "./navigation-menu"

const APP_PROFILE_ICON_ID = "app-profile-icon"

/**
 * @type {React.FunctionComponent<any>}
 */
const AppHeader = () => {
  const {show: showLogout} = useToggle({id: APP_PROFILE_ICON_ID})

  return (
    <>
      <div className="sw-app-header">
        <div>
          <NavigationMenu />
          <Spacing width={32} />
          <img height={40} width={40} src={IC_WORKSPACE} alt={IC_WORKSPACE} />
          <Spacing spacing="l" />
          <span className="title">{getWorkspace()}</span>
        </div>
        <div>
          <IconButton
            icon="btn-settings"
            aria-label="open dialog for settings"
            size={30}
            onClick={() => navigate("/app/settings")}
          />
          <Spacing spacing="none" width={30} />
          <div className="sw-dropdown inline-flex">
            <IconButton
              icon="btn-profile"
              id={APP_PROFILE_ICON_ID}
              aria-label="open logout button"
              size={30}></IconButton>
            <div className={`dropdown-menu logout ${showLogout ? "show" : ""}`}>
              <button className="row" onClick={logout}>
                <Icon icon="ic-logout" size={20} />
                <span className="ml-xxs">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppHeader
