import * as React from "react"
import {Router} from "@reach/router"
import Loadable from "@loadable/component"

import AppProvider from "../containers/app-provider"

import PrivateRoute from "../components/private-route"
import ErrorBoundary from "../components/error-boundary"
import AppRoute from "../app-pages/app-route"

const LoadableSignInRoute = Loadable(() =>
  import(/* webpackPrefetch: true */ "../app-pages/signin-route"),
)

const App = () => {
  return (
    <ErrorBoundary>
      <AppProvider>
        <Router basepath="/app">
          <LoadableSignInRoute path="/auth/*" />
          <PrivateRoute as={AppRoute} path="/*" />
        </Router>
      </AppProvider>
    </ErrorBoundary>
  )
}

export default App
