import * as React from "react"
import {Redirect} from "@reach/router"

import {isLoggedIn} from "../services/auth"

/**
 * @typedef {object} PrivateRouteProps
 * @property {React.Component} as
 *
 * @typedef {import('gatsby').PageProps<object, object>} PageProps
 */

/**
 * @type {React.FunctionComponent<PrivateRouteProps & PageProps>}
 */
const PrivateRoute = ({as: Component, ...pageProps}) => {
  const isAuthPath = pageProps.location.pathname.startsWith("/app/auth")

  if (!isLoggedIn() && !isAuthPath) {
    return (
      <Redirect
        noThrow
        to="/app/auth/signin"
        state={{
          redirectURL: pageProps.location.pathname,
        }}
      />
    )
  }

  return <Component {...pageProps} />
}

export default PrivateRoute
