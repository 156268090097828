import {useInfiniteQuery as useInfiniteQueryOrig} from "react-query"

import {useDefaultOnErrorHandler} from "./utils"

/**
 *
 * @param {import('react-query').QueryKey} queryKey
 * @param {import('react-query').QueryFunction} queryFn
 * @param {import('react-query').UseInfiniteQueryOptions} config
 */
export function useInfiniteQuery(queryKey, queryFn, config) {
  const {onError, ...restConfig} = config

  const onErrorHandler = useDefaultOnErrorHandler(onError, {
    networkToast: false,
  })
  return useInfiniteQueryOrig(queryKey, queryFn, {
    ...restConfig,
    onError: onErrorHandler,
  })
}

/**
 * @param {import('react-query').QueryKey} queryKey
 * @param {import('react-query').QueryFunction} queryFn
 * @param {import('react-query').UseInfiniteQueryOptions} config
 */
export function useInfiniteQueryWithCancel(queryKey, queryFn, config) {
  const {onError, ...restConfig} = config

  const onErrorHandler = useDefaultOnErrorHandler(onError, {
    networkToast: false,
  })

  return useInfiniteQueryOrig(
    queryKey,
    (...args) => {
      // Create a new AbortController instance for this request
      const controller = new AbortController()
      // Get the abortController's signal
      const signal = controller.signal

      const promise = queryFn(...args, {signal})
      promise.cancel = () => {
        // console.log("infinite request canceled", queryKey)
        controller.abort()
      }
      return promise
    },
    {
      ...restConfig,
      onError: onErrorHandler,
    },
  )
}

/**
 * @typedef {import('react-query').QueryFunction}
 */
