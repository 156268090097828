import {useQuery as useQueryOrig} from "react-query"

import {useDefaultOnErrorHandler} from "./utils"

/**
 * @param {import('react-query').UseQueryOptions} options
 */
export function useQuery(options) {
  const {onError, ...restOptions} = options

  const onErrorHandler = useDefaultOnErrorHandler(onError, {
    networkToast: false,
  })

  return useQueryOrig({
    ...restOptions,
    onError: onErrorHandler,
  })
}

/**
 * @param {import('react-query').UseQueryOptions} options
 */
export function useQueryWithCancel(options) {
  const {onError, queryFn, ...restOptions} = options

  const onErrorHandler = useDefaultOnErrorHandler(onError, {
    networkToast: false,
  })

  return useQueryOrig({
    ...restOptions,
    queryFn: (...args) => {
      // Create a new AbortController instance for this request
      const controller = new AbortController()
      // Get the abortController's signal
      const signal = controller.signal

      const promise = queryFn(...args, {signal})
      promise.cancel = () => {
        // console.log("request canceled", restOptions.queryKey)
        controller.abort()
      }
      return promise
    },
    onError: onErrorHandler,
  })
}
