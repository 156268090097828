import * as React from "react"
import classNames from "classnames"
import {navigate} from "gatsby"

import {IconButton} from "./base/icon-button"
import NavigationMenuItem from "./navigation-menu-item"
// Hide the toggle menu interaction now, (open it later)
// import {useToggle} from "../hooks/toggle"

import "./navigation-menu.scss"

/**
 * @type {import('./navigation-menu-item').NavigationMenuItemData[]}
 */
const menu = [
  {
    id: "employees",
    name: "Employees",
    icon: "ic-menu-employee",
    href: "/app/employee",
  },
  // {
  //   id: "immunityPassport",
  //   name: "Immunity Passport",
  //   icon: "ic-menu-immunity-passport",
  // },
  // {
  //   id: "facilities",
  //   name: "Facilities",
  //   icon: "ic-menu-facilities",
  // },
  // {
  //   id: "information",
  //   name: "Information",
  //   icon: "ic-menu-information",
  // },
]

/**
 * @typedef {object} NavigationMenuProps
 * @property {string} id
 */

/**
 * @type {React.FC<NavigationMenuProps>}
 * @param {NavigationMenuProps} param0
 */
const NavigationMenu = ({id = "navigationMenuButton"}) => {
  // Hide the toggle menu interaction now, (open it later)
  // const {show: pressed} = useToggle({
  //   id,
  // })

  const onMenuClickHandler = React.useCallback(() => {
    navigate("/app/employee")
  }, [])

  return (
    <div className="sw-dropdown inline-flex">
      <IconButton
        icon="btn-menu"
        size={40}
        id={id}
        className={classNames("dropdown-toggle", {
          // active: pressed,
        })}
        onClick={onMenuClickHandler}
        // aria-expanded={pressed}
      />
      <ul
        className={classNames("dropdown-menu navigation-menu", {
          // show: pressed,
        })}
        aria-labelledby={id}>
        <li>
          <span className="dropdown-header">dt Smartworks 360</span>
        </li>
        {menu.map(menuItem => (
          <li key={menuItem.id}>
            <NavigationMenuItem
              className="dropdown-item navigation-menu-item"
              {...menuItem}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NavigationMenu
