/**
 * @description this would be used in pages/app.js,
 * it is used to config something
 */
import * as React from "react"

import {QueryProvider} from "../hooks/react-query"
import {ToastContainerRoot} from "../lib/toast"
import {ToastMessage} from "../components/base/toast-message"

export default function AppProvider({children}) {
  return (
    <QueryProvider>
      <ToastContainerRoot ToastUI={ToastMessage}>{children}</ToastContainerRoot>
    </QueryProvider>
  )
}
