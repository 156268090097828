import * as React from "react"
import {css} from "@emotion/core"

/**
 * @typedef {object} IconOwnProps
 * @property {string=} icon
 * @property {number=} size
 * @property {number=} width
 * @property {number=} height
 *
 * @typedef {React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>} SpanProps
 * @typedef {SpanProps & IconOwnProps} IconProps
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLImageElement, IconProps>}
 */
const IconForwardRefRender = (
  {icon = "btn-upload", size = 20, width, height, ...props},
  ref,
) => {
  return (
    <span
      css={css`
        background-image: url(${require(`../../images/${icon}.svg`)});
        background-size: ${width || size}px ${height || size}px;
        background-repeat: no-repeat;
        background-position: center;
        width: ${width || size}px;
        height: ${height || size}px;
        display: inline-block;
      `}
      ref={ref}
      {...props}
    />
  )
}

/**
 * @type {React.ForwardRefExoticComponent<IconProps>}
 */
export const Icon = React.forwardRef(IconForwardRefRender)
