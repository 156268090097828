import {useEffect, useState} from "react"

export function useToggle({id}) {
  const [show, setShow] = useState(false)
  useEffect(() => {
    /** @param {MouseEvent} e */
    const onClickHandler = e => {
      if (e.target.id === id) {
        setShow(v => !v)
      } else {
        setShow(false)
      }
    }
    window.addEventListener("click", onClickHandler)
    return () => {
      window.removeEventListener("click", onClickHandler)
    }
  }, [id])

  return {
    show,
    className: show ? "show" : "",
  }
}
